import { memo } from 'react'
import { Box, HStack, Heading, Text } from '@chakra-ui/react'

export const Header = memo(() => (
  <Box as="header" w="100%" px="32px" alignItems="center" boxShadow="sm">
    <HStack justify="space-between" align="center" maxW="8xl" h="4.5rem" mx="auto">
      <Heading>Tracker</Heading>

      <Text>Features</Text>
    </HStack>
  </Box>
))
