import { createElement } from 'react'
import { RouteObject } from 'react-router-dom'
import { Root } from '../../screens/Root/Root'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: createElement(Root)
  }
]
