import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { routes } from './modules/router'
import { Header } from './modules/components/Header';
import { chakraTheme } from './config/chakra-theme';

function App() {
  const router = createBrowserRouter(routes)

  return (
    <ChakraProvider theme={chakraTheme}>
      <Header />

      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
